import React from "react"

interface types {
	width?: number | string | undefined;
	height?: number | string | undefined;
}

const Linkedin = ({width= 41, height = 42}:types) => {
	return (
		<svg width={width} height={height} viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="20.0239" cy="21.0239" r="18.0239" fill="white"/>
			<path d="M15.5497 11.8512C15.5497 13.0809 14.4838 14.0778 13.1689 14.0778C11.854 14.0778 10.7881 13.0809 10.7881 11.8512C10.7881 10.6214 11.854 9.62451 13.1689 9.62451C14.4838 9.62451 15.5497 10.6214 15.5497 11.8512Z" fill="#1D1E29"/>
			<path d="M11.1137 15.7145H15.1834V28.0468H11.1137V15.7145Z" fill="#1D1E29"/>
			<path d="M21.7357 15.7145H17.6659V28.0468H21.7357C21.7357 28.0468 21.7357 24.1644 21.7357 21.737C21.7357 20.28 22.2331 18.8166 24.2182 18.8166C26.4615 18.8166 26.448 20.7233 26.4376 22.2005C26.4239 24.1313 26.4565 26.1017 26.4565 28.0468H30.5263V21.5381C30.4918 17.3821 29.4088 15.4671 25.8461 15.4671C23.7303 15.4671 22.4188 16.4277 21.7357 17.2967V15.7145Z" fill="#1D1E29"/>
		</svg>
	)
}

export default (Linkedin)
