import React from "react"

interface types {
	width?: number | string | undefined;
	height?: number | string | undefined;
}

const Elm = ({width= 41, height = 42}:types) => {
	return (
		<svg width={width} height={height} viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="20.0239" cy="21.0239" r="18.0239" fill="white"/>
			<path d="M7 24.6862V17H12.5576V17.907H8.01714V20.2611H12.2692V21.1629H8.01714V23.7792H12.7358V24.6862H7Z" fill="#1D1E29"/>
			<path d="M17.0875 24.6862V17H18.1047V23.7792H21.8901V24.6862H17.0875Z" fill="#1D1E29"/>
			<path d="M25.6651 24.6862V17H27.196L29.0153 22.4422C29.1831 22.949 29.3054 23.3283 29.3823 23.58C29.4697 23.3003 29.606 22.8896 29.7913 22.3479L31.6316 17H33V24.6862H32.0196V18.2531L29.786 24.6862H28.8685L26.6455 18.143V24.6862H25.6651Z" fill="#1D1E29"/>
		</svg>
	)
}

export default (Elm)
