import React from "react"

interface types {
	width?: number | string | undefined;
	height?: number | string | undefined;
}

const Git = ({width= 41, height = 42}:types) => {
	return (
		<svg width={width} height={height} viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="20.0239" cy="21.0239" r="18.0239" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M20.3035 9C13.5058 9 8 14.5058 8 21.3035C8 26.7478 11.5219 31.3463 16.4125 32.9765C17.0277 33.0842 17.2584 32.715 17.2584 32.3921C17.2584 32.0999 17.243 31.131 17.243 30.1005C14.1518 30.6696 13.352 29.347 13.106 28.6549C12.9675 28.3012 12.3678 27.2092 11.8449 26.917C11.4142 26.6863 10.7991 26.1173 11.8295 26.1019C12.7984 26.0865 13.4904 26.9939 13.7211 27.363C14.8285 29.2239 16.5971 28.701 17.3045 28.3781C17.4122 27.5783 17.7352 27.04 18.0889 26.7325C15.3514 26.4249 12.4908 25.3637 12.4908 20.6576C12.4908 19.3196 12.9675 18.2123 13.7519 17.351C13.6289 17.0434 13.1982 15.7823 13.8749 14.0906C13.8749 14.0906 14.9054 13.7676 17.2584 15.3517C18.2427 15.0749 19.2885 14.9365 20.3343 14.9365C21.3801 14.9365 22.4259 15.0749 23.4102 15.3517C25.7632 13.7522 26.7936 14.0906 26.7936 14.0906C27.4703 15.7823 27.0397 17.0434 26.9167 17.351C27.701 18.2123 28.1778 19.3042 28.1778 20.6576C28.1778 25.3791 25.3018 26.4249 22.5643 26.7325C23.0103 27.1169 23.3948 27.8552 23.3948 29.0086C23.3948 30.6542 23.3794 31.9768 23.3794 32.3921C23.3794 32.715 23.6101 33.0995 24.2253 32.9765C26.6677 32.1519 28.7901 30.5822 30.2937 28.4882C31.7973 26.3942 32.6064 23.8814 32.607 21.3035C32.607 14.5058 27.1012 9 20.3035 9Z" fill="#1D1E29"/>
		</svg>
	)
}

export default (Git)
