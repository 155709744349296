import React from "react"

interface types {
	width?: number | string | undefined;
	height?: number | string | undefined;
}

const Gamicus = ({width= 41, height = 42}:types) => {
	return (
		<svg width={width} height={height} viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="20.0239" cy="21.0239" r="18.0239" fill="white"/>
			<path d="M12.7546 13.0637V14.1274H13.8183H14.8819V15.1911V16.2547H13.8183H12.7546V17.3184V18.382H11.691H10.6273V19.4457V20.5093H9.56365H8.5V23.7003V26.8912H9.56365H10.6273V24.7639V22.6366H11.691H12.7546V24.7639V26.8912H13.8183H14.8819V27.9549V29.0186H17.0092H19.1365V27.9549V26.8912H17.0092H14.8819V25.8276V24.7639H20.2002H25.5185V25.8276V26.8912H23.3912H21.2638V27.9549V29.0186H23.3912H25.5185V27.9549V26.8912H26.5821H27.6458V24.7639V22.6366H28.7094H29.7731V24.7639V26.8912H30.8367H31.9004V23.7003V20.5093H30.8367H29.7731V19.4457V18.382H28.7094H27.6458V17.3184V16.2547H26.5821H25.5185V15.1911V14.1274H26.5821H27.6458V13.0637V12.0001H26.5821H25.5185V13.0637V14.1274H24.4548H23.3912V15.1911V16.2547H20.2002H17.0092V15.1911V14.1274H15.9456H14.8819V13.0637V12.0001H13.8183H12.7546V13.0637ZM17.0092 19.4457V20.5093H15.9456H14.8819V19.4457V18.382H15.9456H17.0092V19.4457ZM25.5185 19.4457V20.5093H24.4548H23.3912V19.4457V18.382H24.4548H25.5185V19.4457Z" fill="#1D1E29"/>
		</svg>

	)
}

export default (Gamicus)
